<template>
    <div class="join-main">
        <div class="join-banner">
            <img class="show" src="../static/images/join/04_jpg1.jpg">
            <div class="bg animate__animated animate__fadeInUp" >
                <p class="title">帝森克罗德新能源</p>
                <p class="sub-title">竭诚为农户和合作伙伴提供优质的</p>
                <p class="content">户用光伏模式解决方案</p>
            </div>
        </div>
        <div id="hzhb" class="hzhb">
            <p class="title animate__animated animate__fadeInUp">户用电站合作伙伴</p>
            <div class="content animate__animated animate__fadeInUp">
                <div class="left-layer">
                    <img src="../static/images/join/04_jpg2.jpg">
                </div>
                <div class="right-layer">
                    <div class="content">
                        <p class="title">公司优势</p>
                        <p class="val">共富宝合作模式为我公司负责出资，农户提供屋顶资源享受固定收益，合作伙伴负责项目开发、建设、运维的户用分布式光伏电站合作开发模式。<br/>竭诚为农户和合作伙伴提供优质的户用光伏模式解决方案。</p>
                    </div>
                    <div class="st">
                        <ul>
                            <li>
                                <p>国有控股</p>
                                <p>资金保障</p>
                            </li>
                            <li>
                                <p>授信开发</p>
                                <p>资金循环</p>
                            </li>
                            <li>
                                <p>利润高</p>
                                <p>回款快</p>
                            </li>
                            <li>
                                <p>发货迅速</p>
                                <p>结算无忧</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="pattern" class="pattern">
            <img src="../static/images/join/04_jpg3.jpg">
            <div class="bg">
                <p class="title animate__animated animate__fadeInUp">工商业 ＆地面合作伙伴</p>
                <div class="content animate__animated animate__fadeInUp">
                    <div class="left-layer">
                        <div class="top-layer">
                            <p class="title">运营模式</p>
                            <p class="content">企业零投入, 提供闲置工商业厂房屋顶, 由我公司投资、建设及运营屋顶分布式光伏电站, 企业可选长期电价折扣或收取屋顶租金; 我公司也可根据企业用电特性定制光伏+智慧能源管理方案, 为企业进一步降低能耗, 实现可视、可控、可优化的综合能源管理服务。</p>
                        </div>
                        <div class="bottom-layer">
                            <p class="title">合作模式</p>
                            <p class="content"><span style="font-size: 13px;font-weight: 300;display:block;margin-bottom: -18px;">水泥屋顶、彩钢瓦屋顶、车棚屋顶均可!</span><br/>屋顶可用面积合计一万平米以上更优!<br/>企业需提供房屋图纸、近一年电费单、产权证明等。<br/>企业征信良好、经营状况良好、用电量大的企业最佳!<br/>建筑物产证合规、图纸齐全</p>
                        </div>
                    </div>
                    <div class="right-layer">
                        <img src="../static/images/join/04_jpg4.jpg">
                    </div>
                </div>
            </div>
        </div>
        <div class="consult">
            <img src="../static/images/join/04_jpg5.jpg">
            <div class="bg">
                <p class="title animate__animated animate__fadeInUp">针对提供屋顶资源的合作伙伴</p>
                <p class="content animate__animated animate__fadeInUp">我公司给与丰厚的奖励，详情咨询<span style="color: #cb3848;margin-left: 10px">400-6767-658</span></p>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import foot from "../components/foot";

    export default {
        name: "Join",
        components: {foot},
        mounted() {
            this.$bus.$emit("headerFixed", false)
        }
    }
</script>

<style lang="less" scoped>
    .join-main {
        .join-banner {
            font-size: 0px;
            position: relative;
            overflow: hidden;
            min-height: 455px;

            img {
                width: 100%;

            }

            img.show {
                animation-name: changeBiger;
                animation-duration:50s;
                animation-timing-function:linear;
                animation-fill-mode:forwards;
            }

            .bg {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .title {
                    font-size: 43px;
                    font-family: "Microsoft YaHei";
                    color: rgba(255, 255, 255, 0.6);
                    font-weight: 100;
                    margin-bottom: 10px;
                    margin-top: 16px;
                }

                .sub-title {
                    font-size: 44px;
                    font-family: "Microsoft YaHei";
                    color: #FFFFFF;
                    font-weight: 500;
                    margin-bottom: 10px;
                }

                .content {
                    font-size: 44px;
                    font-family: "Microsoft YaHei";
                    color: rgba(255, 255, 255, 0.6);
                    font-weight: 100;
                }
            }
        }

        .hzhb {
            height: 610px;
            margin: 0px;

            > .title {
                color: #333333;
                font-size: 52px;
                font-family: "Microsoft YaHei";
                font-weight: 500;
                margin-top: 88px;
                width: 100%;
                text-align: center;
            }

            > .content {
                display: flex;
                flex-direction: row;
                padding: 6% 19% 70px 18.7%;

                .left-layer {

                    img {
                        width: 368px;
                    }
                }

                .right-layer {

                    padding-left: 130px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    > .content {
                        .title {
                            color: #333333;
                            font-size: 27px;
                            font-weight: 400;
                            font-family: "Microsoft YaHei";
                            line-height: unset;
                            margin-top: -6px;
                        }

                        .val {
                            margin-top: 30px;
                            color: #333333;
                            font-size: 13px;
                            font-family: "Microsoft YaHei";
                            font-weight: 400;
                            line-height: 26px;
                        }
                    }

                    .st {
                        ul {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding-right: 10px;

                            li {
                                list-style: none;

                                p:last-child {
                                    margin-top: 5px;
                                    font-family: "Microsoft YaHei";
                                    font-size: 13px;
                                    color: #c0c0c0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .pattern {
            font-size: 0px;
            position: relative;
            height: 840px;

            > img {
                width: 100%;
                height: 100%;
            }

            .bg {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;

                > .title {
                    color: #FFFFFF;
                    font-size: 51px;
                    font-weight: 500;
                    font-family: "Microsoft YaHei";
                    width: 100%;
                    text-align: center;
                    margin-top: 104px;
                }

                > .content {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 45px 19% 0px 18.7%;

                    .left-layer {
                        margin-right: 12%;
                        .title {
                            color: #FFFFFF;
                            font-size: 27px;
                            font-weight: 400;
                            font-family: "Microsoft YaHei";
                            margin-bottom: 20px;
                        }

                        .content {
                            color: #FFFFFF;
                            font-size: 13px;
                            font-weight: 300;
                            font-family: "Microsoft YaHei";
                            line-height: 26px;
                            letter-spacing: 1px;
                        }

                        .bottom-layer {
                            margin-top: 20%;

                            .title {
                                margin-bottom: 0px;
                            }

                            .content {
                                color: #FFFFFF;
                                font-family: "Microsoft YaHei";
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 30px;
                            }
                        }
                    }

                    .right-layer {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        img {
                            width: 469px;
                        }
                    }
                }
            }

        }

        .consult {
            font-size: 0px;
            position: relative;

            img {
                width: 100%;
            }

            .bg {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .title {
                    font-size: 26px;
                    color: #2d2d2d;
                    font-weight: 200;
                    font-family: "Microsoft YaHei";
                }

                .content {
                    margin-top: 24px;
                    color: #333333;
                    font-size: 35px;
                    font-weight: 500;
                    font-family: "Microsoft YaHei";
                }
            }
        }
    }

    @keyframes changeBiger {
        0% {
            transform: scale(1.3);
        }

        100% {
            transform: scale(1);

        }
    }
</style>
